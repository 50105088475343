import React, { useState, useRef } from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import axios from "axios"
import { useForm } from "react-hook-form"
import { CheckCircle, AlertCircle } from 'react-feather';
import ReCAPTCHA from "react-google-recaptcha";
import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import { GOOGLE_SITE_KEY, FORMSPREE_URL } from '../utils/constants'

const Contact = () => {

	const data = useStaticQuery(graphql`
	query {
		contact: file(relativePath: { eq: "contact.png" }) {
			childImageSharp {
				fluid(maxWidth: 700, quality: 100) {
					...GatsbyImageSharpFluid_noBase64
				}
			}
		}
	}
`)

	const { register, handleSubmit, reset, errors } = useForm();
	const [captchaValue, setCaptchaValue] = useState();
	
	const captchaRef = useRef(null);

    const [serverState, setServerState] = useState({
        submitting: false,
        status: null
    });

    const handleServerResponse = (ok, msg) => {
        setServerState({
            submitting: false,
            status: { ok, msg }
        });
        if (ok) {
            reset({
                name: '',
                email: '',
				message: '',
				phone: ''

            });
        }
		setCaptchaValue(null);
		captchaRef && captchaRef.current && captchaRef.current.reset();
    };

    const onSubmit = ({name, email, message, phone}) => {
        setServerState({ submitting: true });
        const formData = new FormData();
        formData.append("name", name);
        formData.append("email", email);
		formData.append("message", message);
		formData.append("phone", phone);
       	formData.append('g-recaptcha-response', captchaValue);
        axios({
            method: "post",
        	url: FORMSPREE_URL,
            data: formData
        }).then(_ => {
            handleServerResponse(true, "Thanks, your message has been sent!");
        }).catch(r => {
            handleServerResponse(false, "Oops, there was an error sending your message: " + r.response.data.error);
        });
    };

    const handleCaptchaChange = value => {
        setCaptchaValue(value);
	}
	
	return(
		<Layout>
			<SEO title="Contact" />
			<div className='contact-page'>
				<div className="row mb-2">
					<div className="col-sm-6 info mb-2 mb-sm-0">
						<h1 className='display title'>Say hi</h1>
						<p className='mb-0'>
							Axe Edge Limited<br/>
							Huntingdon House<br/>
							Huntingdon Street<br/>
							Nottingham NG1 3LY 
						</p>
					</div>
					<div className="col-sm-6">
						<Img fluid={data.contact.childImageSharp.fluid} />
					</div>
				</div>


				{serverState.status && (
					<p className={serverState.status.ok ? "successMsg" : "errorMsg"}>
						{serverState.status.ok ? <CheckCircle /> : <AlertCircle />}
						{serverState.status.msg}
					</p>
				)} 

				{(!serverState.status || (serverState.status && !serverState.status.ok)) && (
					<form onSubmit={handleSubmit(onSubmit)} className='mb-1'>
						<div className="row">
							<div className="col-md-6">
								<div className="basic-form__group">
									<input
										name="name"
										className="basic-form__text-box"
										ref={register({
											required: 'Please enter your name',
										})}
										type="text"
										placeholder="Name*"
									/>
								</div>
								{errors.name && <p className='basic-form__hint'>{errors.name.message}</p>}
				
								<div className="basic-form__group">
									<input
										name="email"
										className="basic-form__text-box"
										ref={register({
											required: 'Please enter your email',
										})}
										type="email"
										placeholder="Email*"
									/>
								</div>
								{errors.email && <p className='basic-form__hint'>{errors.email.message}</p>}

								<div className="basic-form__group">
									<input
										name="phone"
										className="basic-form__text-box"
										ref={register({
											required: 'Please enter your phone number',
										})}
										type="text"
										placeholder="Phone*"
									/>
								</div>
								{errors.phone && <p className='basic-form__hint'>{errors.phone.message}</p>}
		
							</div>
							<div className="col-md-6 msg-form-group">
								<div className="basic-form__group msg-form-group">
									<textarea
										name="message"
										className="basic-form__text-box"
										ref={register({
											required: 'Please write your message',
										})}
										placeholder="Message*"
										rows="4"
									></textarea>
									{errors.message && <p className='basic-form__hint mt-0'>{errors.message.message}</p>}
								</div>
							</div>
						</div>

						<div className="mb-2">
							<ReCAPTCHA
								ref={r => captchaRef.current = r}
								sitekey={GOOGLE_SITE_KEY}
								onChange={(e) => handleCaptchaChange(e)}
							/>
						</div>
						<button type="submit" disabled={serverState.submitting || !captchaValue} className='btn btn-primary'>{serverState.submitting ?  'Sending' : 'Send'}</button>    
					</form>
				)} 
			</div>
		</Layout>
	)

}
export default Contact
